import './style/home.scss';

import React, {PureComponent} from 'react';
import {navigate} from '@dvrd/dvr-controls';
import ParBlock from "../parBlock/parBlock";
import {WithHelmet} from "../misc/withHelmet";
import {sendGaPageview} from "../../util/analytics";
import {pageScroller} from "../../util/utils";

export default class Home extends PureComponent {
    onNavigate = (route: string, move: string) => (evt: React.MouseEvent) => {
        evt.preventDefault();
        navigate(route, true, move, pageScroller);
    };

    componentDidMount = () => {
        sendGaPageview('/home');
    };

    renderAbout = () => {
        return (
            <ParBlock id='home-content' colored={false}>
                <div className='about-hvr-container'>
                    <div className='text-container'>
                        <label className='par-title'>Management advies</label>
                        <p className='par-text nm'>HVR administraties biedt de klant een totaalpakket (boekhouding,
                            fiscale aangiften en advies) tegen een zeer scherpe prijs. Vraag een vrijblijvende <a
                                href='/tarieven' onClick={this.onNavigate('/tarieven', 'offer-content')}
                                className='link'>offerte</a> aan onder het kopje tarieven. De behandeling van vragen van
                            de klant wordt niet in rekening gebracht. Hiermee onderscheidt HVR Administraties zich van
                            vele andere kantoren. HVR Administraties richt zich in eerste instantie op bedrijven in de
                            nabije omgeving van Woerden, Mijdrecht, Wilnis, Vinkeveen, Uithoorn, Breukelen, Hilversum en
                            Abcoude. <br/><br/>Het administratiekantoor is opgericht op 11 december 2013.</p>
                    </div>
                </div>
            </ParBlock>
        )
    };

    renderAboutMe = () => {
        return (
            <ParBlock>
                <div className='about-me'>
                    <label className='par-title'>Maak kennis met Hans van Rijn</label>
                    <div className='content'>
                        <p className='par-text nm'>
                            Wat ik leuk vind in mijn vak is het helpen van mensen in het bijzonder met hun
                            administraties, fiscale aangifte(n) of het geven van advies. Mijn tarieven behoren tot de
                            meest concurrerende tarieven in de markt.<br/><br/>De software maakt dat processen
                            efficiënter worden. Hierdoor zal een deel van het uitvoerende werk verdwijnen. Hierdoor kun
                            je meer advies geven aan bestaande klanten dan wel meer klanten in dezelfde tijd behandelen.
                            Wat ik voor iedere klant wil bereiken is het juist voeren van een administratie en het
                            tijdig en juist doen van aangiftes. Hiermee wil ik een lange termijn relatie met de klant
                            opbouwen.
                        </p>
                        {/*<img src={getImg('hans')} alt='HVR Administraties - Hans' className='photo'/>*/}
                    </div>
                </div>
            </ParBlock>
        )
    };

    render = () => {
        return (
            <WithHelmet title='HVR Administraties | Home' canonical='https://hvradministraties.nl/home'>
                <div className='home'>
                    {this.renderAbout()}
                    {this.renderAboutMe()}
                </div>
            </WithHelmet>
        );
    };
}