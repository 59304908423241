import './style/contactPage.scss';

import React from 'react';
import {PureComponent} from 'react';
import {AwesomeIcon} from '@dvrd/dvr-controls';
import ParBlock from "../../parBlock/parBlock";
import {WithHelmet} from "../../misc/withHelmet";
import {sendGaPageview} from "../../../util/analytics";

export default class ContactPage extends PureComponent {
    componentDidMount = () => {
        sendGaPageview('/contact');
    };

    render = () => {
        return (
            <WithHelmet title='HVR Administraties | Contact' canonical='https://hvradministraties.nl/contact'>
                <div className='contact-page'>
                    <ParBlock colored={false} id='contact-content'>
                        <div className='icon-content-container'>
                            <AwesomeIcon name='phone-alt' className='icon'/>
                            <div className='text-container'>
                                <label className='par-title'>Contact</label>
                                <p className='par-text nm'>
                                    U kunt contact opnemen voor het maken van een afspraak of als u een vraag heeft. U
                                    kunt hiervoor onderstaand contactformulier gebruiken. U kunt mij ook telefonisch
                                    bereiken als u liever persoonlijk contact heeft. HVR Administraties is dagelijks per
                                    mail en contactformulier bereikbaar van <span style={{whiteSpace: 'nowrap'}}>7:30 – 21:30 uur</span>.
                                </p>
                            </div>
                        </div>
                    </ParBlock>
                    <ParBlock>
                        <div className='contact-info-container'>
                            <label className='title'>Contactgegevens</label>
                            <div className='row'>
                                <div className='col'>
                                    <div className='col-content'>
                                        <label className='col-title'>Adres</label>
                                        <label className='col-value'>Hans van Rijn</label>
                                        <label className='col-value'>Achterbos 69-B</label>
                                        <label className='col-value'>3645 CB Vinkeveen</label>
                                    </div>
                                </div>
                                <div className='col'>
                                    <div className='col-content'>
                                        <label className='col-title'>Bereikbaarheid</label>
                                        <label className='col-value'><AwesomeIcon name='envelope'/> <a
                                            className='col-link'
                                            href='mailto:info@hvradministraties.nl'>info@hvradministraties.nl</a></label>
                                        <label className='col-value'><AwesomeIcon name='phone-alt'/> <a
                                            className='col-link'
                                            href='tel:+31613124959'>06-13124959</a></label>
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <label className='row-value'>HVR Administraties is dagelijks per mail en
                                    contactformulier bereikbaar van 7:30 – 21:30 uur.</label>
                            </div>
                        </div>
                    </ParBlock>
                </div>
            </WithHelmet>
        )
    };
}