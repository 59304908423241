import './style/servicePage.scss';

import React from 'react'
import {PureComponent} from 'react'
import {WithHelmet} from "../misc/withHelmet";
import ParBlock from "../parBlock/parBlock";
import {sendGaPageview} from "../../util/analytics";

export default class ServicePage extends PureComponent {
    onClickContact = () => {
        const form = document.getElementById('contact-form');
        if (form) form.scrollIntoView({block: 'center', behavior: 'smooth'});
    };

    componentDidMount = () => {
        sendGaPageview('/diensten');
    };

    render = () => {
        return (
            <WithHelmet title='HVR Administraties | Diensten' canonical='https://hvradministraties.nl/diensten'>
                <div className='service-page'>
                    <ParBlock id='service-content'>
                        <div className='text-container'>
                            <label className='par-title'>Het belang van een goede administratie</label>
                            <p className='par-text nm'>
                                Geen enkele onderneming of ondernemer kan zonder een deugdelijke administratie. Zonder
                                cijfers heeft uw organisatie geen basis en kunt u het zakenschip niet gericht bijsturen
                                op de woelige economisch-maatschappelijke zee van het bedrijfsleven. Door elke maand de
                                balans op te maken, weet u precies hoe de zaken ervoor staan. Bovendien geeft inzicht in
                                de financiën u de mogelijkheid om tijdig bij te sturen en duurzame
                                toekomststrategieën uit te stippelen. Het bijhouden van een overzichtelijke
                                administratie
                                is bovendien niet alleen belangrijk voor uzelf, maar ook voor diverse externe partijen
                                zoals de Belastingdienst, bank of verzekeringsmaatschappij. HVR Administraties
                                versoepelt het administratieproces.
                            </p>
                            <p className='par-text'>
                                Het opzetten en bijhouden van de financiële administratie lijkt vaak een lastige en
                                tijdrovende uitdaging, maar kan gemakkelijker zijn dan u denkt. HVR Administraties helpt
                                u met dat proces. Bijvoorbeeld door te kijken naar automatiseringsmogelijkheden en
                                praktische administratietips. Het mooie is dat de mate van betrokkenheid geheel
                                afhankelijk is van uw wensen. Wilt u bepaalde administratieve taken geheel uitbesteden?
                                Of vat u liever zelf de koe bij de horens, waarbij ik met u meedenk/-kijk en slechts
                                sporadisch in beeld kom? De keus is geheel aan u. HVR Administraties geeft u de vrijheid
                                om het pakket te kiezen dat optimaal aansluit op uw persoonlijke wensen en voorkeuren.
                                Maatwerk tegen betaalbare prijzen is daarbij mijn credo.
                            </p>
                        </div>
                    </ParBlock>
                    <ParBlock colored={false}>
                        <div>
                            <label className='par-title'>De rol van de administrateur</label>
                            <p className='par-text'>
                                Een administrateur/boekhouder is een breed begrip voor iemand die alle facetten van
                                administratie en belastingzaken beheerst. Ieder bedrijf heeft een financiële
                                administratie. Veelal is dit een hele opgave om dit zelf te doen, met name bij
                                éénmanszaken, zzp-ondernemers of het gehele MKB. Een administrateur kan hierbij het
                                werk uit handen nemen en doet dit vaak in een veel kortere tijd dan wanneer men dit zelf
                                zou doen.
                            </p>
                            <p className='par-text'>
                                HVR Administraties helpt bedrijven om hun administratie in orde te brengen en te
                                houden. Hieronder volgt een opsomming van werkzaamheden van HVR Administraties:
                            </p>
                            <label className='par-title sm mt2'>Advies - Meedenken en vooruitkijken met de ondernemer en
                                particulier</label>
                            <div className='par-text nm'>
                                <div className='par-list'>
                                    <label className='item'>Het invoeren van de administratie</label>
                                    <label className='item'>Inrichting of aanpassing van uw administratie</label>
                                    <label className='item'>Het opstellen van de jaarrekening</label>
                                    <label className='item'>Het maken van managementrapportages</label>
                                    <label className='item'>Aangifte omzetbelasting</label>
                                    <label className='item'>Het opstellen van een ondernemingsplan</label>
                                    <label className='item'>Financieel klankbord</label>
                                </div>
                            </div>
                            <label className='par-title sm mt'>Aangifte - Bent u ondernemer met een Eenmanszaak, VOF,
                                Maatschap of bent u particulier?</label>
                            <div className='par-text nm'>
                                <div className='par-list'>
                                    <label className='item'>Aangifte inkomstenbelasting particulier</label>
                                    <label className='item'>BTW-kwartaalaangfite</label>
                                    <label className='item'>Jaarcijfers + aangifte inkomstenbelasting ondernemer</label>
                                </div>
                            </div>
                        </div>
                    </ParBlock>
                    {/*<ParBlock colored={false}>*/}
                    {/*    <label className='par-title'>Waarom moet een bedrijf een administratie bijhouden?</label>*/}
                    {/*    <p className='par-text'>*/}
                    {/*        De ondernemer is wettelijk verplicht om een administratie bij te houden en te bewaren. De*/}
                    {/*        bewaartermijn is 7 jaar. Wanneer er onroerend goed transacties plaatsvinden is de*/}
                    {/*        bewaartermijn 10 jaar (is echter niet wettelijk). Naast deze wettelijke verplichting wilt*/}
                    {/*        u als eigenaar van het bedrijf weten hoe uw bedrijf er financieel voor staat. Om deze*/}
                    {/*        vraag te kunnen beantwoorden is het inrichten van een goede administratie van essentieel*/}
                    {/*        belang.*/}
                    {/*    </p>*/}
                    {/*</ParBlock>*/}
                    {/*<ParBlock>*/}
                    {/*    <label className='par-title'>Hoe ziet een goede en juiste administratie eruit?</label>*/}
                    {/*    <p className='par-text'>*/}
                    {/*        Dit zal per type bedrijf er net iets anders uitzien. Er is wel een rode draad in al deze*/}
                    {/*        administraties te ontdekken.*/}
                    {/*    </p>*/}
                    {/*    <div className='par-text'>*/}
                    {/*        <div className='par-list'>*/}
                    {/*            <label className='item'>Kasadministratie (ook kladaantekeningen) en kassabonnen mits van*/}
                    {/*                toepassing</label>*/}
                    {/*            <label className='item'>Inkoop- en verkoopfacturen (papier en/of digitaal)</label>*/}
                    {/*            <label className='item'>Bankafschriften (papier en/of elektronisch)</label>*/}
                    {/*            <label className='item'>Offertes, contracten, overeenkomsten en overige*/}
                    {/*                afspraken</label>*/}
                    {/*            <label className='item'>Agenda’s en afsprakenboeken</label>*/}
                    {/*            <label className='item'>Bijhouden van zakelijke uren en zakelijke kilometers</label>*/}
                    {/*            <label className='item'>(Email)correspondentie</label>*/}
                    {/*            <label className='item'>Software en databestanden</label>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <p className='par-text'>*/}
                    {/*        Er wordt dus veel verwacht van de ondernemer zodra hij/zij een onderneming start. HVR*/}
                    {/*        Administraties kan hier de juiste persoon zijn om de administratie op te zetten en bij te*/}
                    {/*        houden. Hierdoor heeft de ondernemer meer tijd om zich te focussen op activiteiten waar*/}
                    {/*        hij/zij beter in is, een echte win-win situatie.*/}
                    {/*    </p>*/}
                    {/*    <p className='par-text'>*/}
                    {/*        Een administrateur heeft hier dus een ondersteunende rol in, en conform de afspraken bepaalt*/}
                    {/*        de opdrachtgever welke onderdelen kunnen worden uitbesteed.*/}
                    {/*        “Administrateur is een ondersteunde rol in de bedrijfsadministratie”.*/}
                    {/*    </p>*/}
                    {/*</ParBlock>*/}
                    {/*<ParBlock colored={false}>*/}
                    {/*    <label className='par-title'>Administratie die kloppend is door de administrateur</label>*/}
                    {/*    <p className='par-text'>*/}
                    {/*        Het is essentieel dat de administratie kloppend is. Uiteraard blijft het mensenwerk en*/}
                    {/*        kunnen er fouten voorkomen, echter wordt deze kans zeer verkleind wanneer de administratie*/}
                    {/*        wordt gedaan door een administrateur.*/}
                    {/*    </p>*/}
                    {/*</ParBlock>*/}
                    <ParBlock>
                        <label className='par-title'>Het gesprek met de ondernemer</label>
                        <p className='par-text'>
                            De uitkomst van alle ingevoerde cijfers is 1 deel van het verhaal. Veel belangrijker is het
                            gesprek met de ondernemer over deze cijfers, de richting die het bedrijf op wil, de
                            systemen of het juist ontbreken van de systemen en de inrichting van de processen binnen
                            het bedrijf.
                        </p>
                        <p className='par-text'>
                            Hier speel ik als administrateur een belangrijke rol samen met de ondernemer. Ik kan
                            hem/haar voorzien van gedegen advies over aanschaf van software of juist vervanging van
                            software, het aanpassen/aanbrengen van processen en meedenken over de investeringsbeslissing
                            of juist de vooruitzichten van een liquiditeitsoverzicht.
                        </p>
                    </ParBlock>
                    <ParBlock colored={false}>
                        <p className='par-text center'>Ik ga graag het gesprek aan over uw administratie. Ik wil u graag
                            naar het <span className='par-link'
                                           onClick={this.onClickContact}>contactformulier</span> verwijzen wanneer u
                            m.b.t het bovenstaande vragen heeft.</p>
                    </ParBlock>
                </div>
            </WithHelmet>
        )
    };
}