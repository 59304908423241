import {getMode} from "@dvrd/dvr-controls";

const prodMode = (): boolean => getMode() === 'prod';

export const sendGaPageview = (location?: string) => {
    if (prodMode()) {
        const trackingId = getTrackingID();
        if (trackingId && window.gtag) {
            if (location && location.length) {
                if (!location.startsWith('/')) location = '/' + location;
                window.gtag('config', trackingId, {'page_path': location});
            } else window.gtag('config', trackingId);
        }
    }
};

export const sendGaEvent = (action: string, category?: string, label?: string, value?: string) => {
    if (prodMode() && getTrackingID() && window.gtag)
        window.gtag('event', action, {event_category: category, event_label: label, value});
};

const getTrackingID = (): string | null => {
    return window.allowCookies && prodMode() ? window.settings.gaID : null;
};