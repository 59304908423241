import './style/footer.scss';

import React, {MouseEventHandler, PureComponent} from 'react';
import {AwesomeIcon} from '@dvrd/dvr-controls';
import ContactFormController from "../contact/form/contactFormController";
import Logo from "../logo/logo";
import {Navigation} from "../../util/interfaces";
import {NavigationItems} from "../../routes";

interface Props {
    onNavigate: (item: Partial<Navigation>) => MouseEventHandler;
    onClickLegal: (name: 'conditions' | 'privacy') => MouseEventHandler;
}

export default class Footer extends PureComponent<Props> {
    renderCompanyInfo = () => {
        return (
            <div className='company-info-container'>
                <div className='content-container'>
                    <label className='company-info bold title'>HVR Administraties</label>
                    <a href='https://www.kvk.nl/zoeken/?source=all&q=59406518' target='_blank'
                       className='company-info link'>
                        <AwesomeIcon name='building' className='info-icon'/>
                        <span className='info-text'>KvK: 59406518</span>
                    </a>
                    <a href='mailto:info@hvradministraties.nl' target='_blank' className='company-info link'>
                        <AwesomeIcon name='envelope' className='info-icon'/>
                        <span className='info-text'>info@hvradministraties.nl</span>
                    </a>
                    <a href='tel:+31613124959' target='_blank' className='company-info link'>
                        <AwesomeIcon name='phone-alt' className='info-icon'/>
                        <span className='info-text'>06-13124959</span>
                    </a>
                </div>
            </div>
        )
    };

    renderLogoContainer = () => {
        const {onNavigate} = this.props;
        return (
            <div className='logo-container'>
                <Logo onClick={onNavigate({route: '/home'})}/>
            </div>
        )
    };

    renderNavigation = () => {
        return (
            <div className='navigation-container'>
                <div className='content-container'>
                    {NavigationItems.map(this.renderNavigationItem)}
                </div>
            </div>
        );
    };

    renderNavigationItem = (item: Navigation, index: number) => {
        const {onNavigate} = this.props;
        return (
            <div key={index} className='navigation-item' onClick={onNavigate(item)}>
                <label className='item-label'>{item.label}</label>
            </div>
        )
    };

    render = () => {
        const {onClickLegal} = this.props;
        return (
            <div className='footer-container'>
                <ContactFormController/>
                <div className='footer'>
                    {this.renderCompanyInfo()}
                    {this.renderLogoContainer()}
                    {this.renderNavigation()}
                </div>
                <div className='legal-container'>
                    <label className='legal-link' onClick={onClickLegal('conditions')}>Algemene voorwaarden</label>
                    <label className='legal-link' onClick={onClickLegal('privacy')}>Privacy policy</label>
                </div>
                <div className='copyright'>
                    <a className='copyright-label' target='_blank' href='https://davevanrijn.nl'>Ontwikkeld door Dave
                        van Rijn Development</a>
                </div>
            </div>
        )
    }
}