import './style/contactForm.scss';

import React, {FocusEventHandler, MouseEventHandler} from 'react';
import {Checkbox, DvrdButton, DvrdInput, Loader, preventDefault} from '@dvrd/dvr-controls';
import {BusinessType, ChangeKeyFunction, ContactType, ErrorType} from "../../../util/interfaces";
import Logo from "../../logo/logo";

interface Props {
    onChange: ChangeKeyFunction;
    onResetError: (key: 'name' | 'email') => FocusEventHandler;
    onSubmit: MouseEventHandler;
    name: string;
    email: string;
    phoneNumber: string;
    contactType: ContactType;
    businessType: BusinessType;
    message: string;
    loading: boolean;
    errors: {
        name: ErrorType;
        email: ErrorType;
    }
}

export default function ContactForm(props: Props) {
    const {
        name, email, phoneNumber, contactType, businessType, message, onChange, errors, onSubmit, onResetError,
        loading
    } = props;

    function onChangeContactType(value: ContactType) {
        return function () {
            onChange('contactType')(value);
        }
    }

    function onChangeBusinessType(businessType: BusinessType) {
        return function () {
            onChange('businessType')(businessType);
        }
    }

    function renderContactType() {
        return (
            <div className='radio-items'>
                <label className='radio-title'>Soort contact</label>
                <Checkbox checked={contactType === ContactType.QUOTATION}
                          onCheck={onChangeContactType(ContactType.QUOTATION)} label='Offerte'/>
                <Checkbox checked={contactType === ContactType.QUESTION}
                          onCheck={onChangeContactType(ContactType.QUESTION)} label='Vraag'/>
                <Checkbox checked={contactType === ContactType.MISC}
                          onCheck={onChangeContactType(ContactType.MISC)} label='Overig'/>
            </div>
        )
    }

    function renderBusinessType() {
        return (
            <div className='radio-items'>
                <label className='radio-title'>Ondernemingsvorm</label>
                <Checkbox checked={businessType === BusinessType.ZZP} onCheck={onChangeBusinessType(BusinessType.ZZP)}
                          label='ZZP-ondernemer / Eenmanszaak'/>
                <Checkbox checked={businessType === BusinessType.VOF} onCheck={onChangeBusinessType(BusinessType.VOF)}
                          label='VOF-ondernemer'/>
                <Checkbox checked={businessType === BusinessType.PRIVATE}
                          onCheck={onChangeBusinessType(BusinessType.PRIVATE)} label='Particulier'/>
            </div>
        )
    }

    return (
        <div className='contact-form' id='contact-form'>
            <Loader active={loading}/>
            <Logo text='Contact' fontSize='1.2rem' className='form-title'/>
            <form className='form-container' onSubmit={preventDefault}>
                {renderContactType()}
                {renderBusinessType()}
                <DvrdInput onChange={onChange('name')} value={name} label='Naam *' error={errors.name}
                           onFocus={onResetError('name')}/>
                <DvrdInput onChange={onChange('email')} value={email} label='E-mailadres *' error={errors.email}
                           onFocus={onResetError('email')}/>
                <DvrdInput onChange={onChange('phoneNumber')} value={phoneNumber} label='Telefoonnummer'/>
                <DvrdInput onChange={onChange('message')} value={message}
                           label={`Waarmee kan ik u van dienst zijn? (${message.length} / 500 tekens)`} area
                           inputProps={{rows: 10}}/>
                <DvrdButton onClick={onSubmit} label='Verzenden' customBaseColor='#3a4044' className='submit-btn'
                            customContrastColor='#d8d8d8' disabled={loading}/>
            </form>
        </div>
    )
}