import './style/custom.scss';

import React from 'react';
import {createRoot} from 'react-dom/client';
import {getSetting} from '@dvrd/dvr-controls';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import AppContextProvider from "./js/components/context/appContext";
import {Routes} from "./js/routes";

const router = createBrowserRouter(Routes, {basename: getSetting('appBaseUrl')});

const rootElement = document.getElementById('content');
if (rootElement) {
    createRoot(rootElement).render(
        <AppContextProvider>
            <RouterProvider router={router}/>
        </AppContextProvider>
    );
}