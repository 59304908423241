import {getElementTop} from '@dvrd/dvr-controls';

export const getImg = (imgName: string, fallbackExtension: string = 'png'): string => {
    const webpSupported = webpIsSupported(), extension = webpSupported ? 'webp' : fallbackExtension;
    try {
        return require(`../../res/img/${imgName}.${extension}`);
    } catch {
        return require(`../../res/img/${imgName}.${fallbackExtension}`);
    }
};

const webpIsSupported = (): boolean => {
    try {
        return document.createElement('canvas').toDataURL('image/webp')
            .indexOf('data:image/webp') === 0;
    } catch {
        return false;
    }
}

const getHeaderHeight = (): number => {
    const header = document.getElementById('app-header');
    if (header)
        return header.getBoundingClientRect().height;
    return 0;
};

export const pageScroller = (id: string) => {
    if (!id.length) window.scroll({top: 0, behavior: "smooth"});
    const element = document.getElementById(id);
    if (element) {
        const top = getElementTop(element);
        window.scroll({top: top - getHeaderHeight() + 1, behavior: 'smooth'});
    }
};