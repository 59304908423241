import React, {PureComponent} from 'react';
import {BusinessType, ContactType, ErrorType, IndexedObject, ResponseData} from "../../../util/interfaces";
import ContactForm from "./contactForm";
import {sendContact} from "../../../util/requestSender";
import {sendGaEvent} from "../../../util/analytics";
import {showDialog, validateEmail} from '@dvrd/dvr-controls';

interface State extends IndexedObject<any> {
    name: string;
    email: string;
    phoneNumber: string;
    contactType: ContactType;
    businessType: BusinessType;
    message: string;
    loading: boolean;
    errors: {
        name: ErrorType;
        email: ErrorType;
    }
}

export default class ContactFormController extends PureComponent<{}, State> {
    state: State = {
        name: '',
        email: '',
        phoneNumber: '',
        contactType: ContactType.QUOTATION,
        businessType: BusinessType.ZZP,
        message: '',
        loading: false,
        errors: {
            name: null,
            email: null,
        }
    };

    onChange = (key: string) => (value: any) => {
        if (key === 'message' && typeof value === 'string')
            value = value.substr(0, Math.min(value.length, 500));
        this.setState({[key]: value});
    };

    onResetError = (key: 'name' | 'email') => () => {
        this.setState({errors: Object.assign({}, this.state.errors, {[key]: null})});
    };

    onSubmit = () => {
        if (this.validateValues()) {
            const {name, email, phoneNumber, message, contactType, businessType} = this.state;
            sendGaEvent('sumbit_contact', contactType, businessType);
            this.setState({loading: true});
            sendContact({
                data: {
                    name,
                    email,
                    phone_number: phoneNumber,
                    message,
                    contact_type: contactType,
                    business_type: businessType
                },
                callback: (data: ResponseData) => {
                    this.setState({loading: false});
                    const message = data.success ? 'Uw bericht is succesvol verzonden. Er zal zo spoedig mogelijk contact ' +
                        'met u opgenomen worden.' : 'Het verzenden van uw bericht is helaas niet gelukt. Probeer ' +
                        'het later alstublieft opnieuw of neem contact op via de mail.',
                        title = data.success ? 'Bericht verzonden' : 'Verzenden mislukt';
                    showDialog(message, title);
                    if (data.success) this.setState({
                        name: '',
                        email: '',
                        phoneNumber: '',
                        contactType: ContactType.QUOTATION,
                        businessType: BusinessType.ZZP,
                        message: '',
                        errors: {
                            name: null,
                            email: null,
                        }
                    })
                },
            })
        }
    };

    validateValues = (): boolean => {
        const {name, email} = this.state, errors = Object.assign({}, this.state.errors);
        let valuesAreValid = true;
        if (!name || !name.length) {
            valuesAreValid = false;
            errors.name = 'Vul uw naam in a.u.b.';
        }
        if (!email || !email.length) {
            valuesAreValid = false;
            errors.email = 'Vul uw e-mailadres in a.u.b.';
        } else if (!validateEmail(email)) {
            valuesAreValid = false;
            errors.email = 'Vul een geldig e-mailadres in a.u.b.';
        }
        if (!valuesAreValid)
            this.setState({errors});
        return valuesAreValid;
    };

    render = () => {
        const {name, email, phoneNumber, contactType, businessType, message, loading, errors} = this.state;
        return <ContactForm onChange={this.onChange} onResetError={this.onResetError} onSubmit={this.onSubmit}
                            name={name} email={email} phoneNumber={phoneNumber} contactType={contactType}
                            businessType={businessType} message={message} loading={loading} errors={errors}/>;
    };
}