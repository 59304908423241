import React from 'react';
import {PureComponent} from "react";
import Footer from "./footer";
import {Navigation} from "../../util/interfaces";
import {sendGaEvent} from "../../util/analytics";
import {navigate} from '@dvrd/dvr-controls';
import {pageScroller} from "../../util/utils";

export default class FooterController extends PureComponent {
    onNavigate = (item: Partial<Navigation>) => () => {
        const {isUrl, route, scrollId} = item;
        if (isUrl) {
            sendGaEvent('navigate', 'footer', route);
            window.open(route);
        } else if (route) {
            sendGaEvent('navigate', 'footer', route);
            const move: string | boolean = scrollId || true;
            navigate(route, true, move, pageScroller)
        }
    };

    onClickLegal = (name: 'conditions' | 'privacy') => () => {
        sendGaEvent('click file', 'legal', name);
        let fileName = '';
        if (name === 'conditions') {
            fileName = 'Algemene Voorwaarden.pdf';
        } else if (name === 'privacy') {
            fileName = 'Privacy Policy.pdf';
        }
        if (fileName.length) {
            const anchor = document.createElement('a');
            anchor.href = require.resolve(`../../../res/files/${fileName}`);
            anchor.download = 'HVR Administraties ' + fileName;
            anchor.target = '_blank';
            anchor.style.display = 'none';
            document.documentElement.appendChild(anchor);
            anchor.click();
            document.documentElement.removeChild(anchor);
        }
    };

    render = () => {
        return <Footer onNavigate={this.onNavigate} onClickLegal={this.onClickLegal}/>;
    };
}