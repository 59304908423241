import './style/logo.scss';

import React from 'react';
import {CSSProperties, MouseEventHandler, PureComponent} from "react";
import classNames from "classnames";

interface Props {
    onClick?: MouseEventHandler;
    className?: string;
    fontSize?: string;
    text?: string;
}

export default class Logo extends PureComponent<Props> {
    private onClick = (evt: React.MouseEvent) => {
        const {onClick} = this.props;
        if (onClick) onClick(evt);
    };

    private getStyle = (): CSSProperties => {
        const {fontSize} = this.props, styles: CSSProperties = {};
        if (fontSize) styles.fontSize = fontSize;
        return styles;
    };

    render = () => {
        const {className, onClick} = this.props;
        const text = this.props.text ?? 'HVR ADMINISTRATIES';
        return (
            <div className={classNames('site-logo-container', onClick !== undefined && 'clickable', className)} onClick={this.onClick}>
                <div className='border'/>
                <div className='text-container'>
                    <label className='logo-text' style={this.getStyle()}>{text}</label>
                </div>
                <div className='border'/>
            </div>
        )
    };
}