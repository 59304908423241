import './style/cookieConsent.scss';

import React from 'react';
import classNames from 'classnames';

import {DvrdButton} from '@dvrd/dvr-controls';
import Cookies from "js-cookie";

interface Props {
    onClose: Function;
    visible: boolean;
}

export default class CookieConsent extends React.Component<Props> {
    onClickAccept = () => {
        Cookies.set('cookie_consent', '1', {expires: 31});
        window.allowCookies = true;
        this.props.onClose();
    };

    onClickDeny = () => {
        Cookies.set('cookie_consent', '0', {expires: 31});
        window.allowCookies = false;
        this.props.onClose();
    };

    render = () => {
        const {visible} = this.props;
        return (
            <div className={classNames('cookie-consent-container', visible && 'visible')}>
                <p className='message'>HVR Administraties maakt gebruik van cookies om de gebruikservaring te
                    verbeteren.</p>
                <div className='button-container'>
                    <DvrdButton onClick={this.onClickAccept} label='Oké, prima!' className='accept-button'
                                customBaseColor='#d8d8d8' customContrastColor='#3a4044'/>
                    <DvrdButton onClick={this.onClickDeny} label='Uitschakelen' customBaseColor='#d8d8d8'
                                customContrastColor='#3a4044'/>
                </div>
            </div>
        )
    };
}