import {BusinessType, ContactType, FetchMethod} from "./interfaces";
import {sendFetch} from "@dvrd/dvr-controls";
import {URLS} from "./api";

interface Callback {
    callback: Function,
}

interface WithData<T> {
    data: T
}

/*
 ================================================
 CONTACT
 ================================================
 */
interface ContactData {
    name: string;
    email: string;
    phone_number: string;
    contact_type: ContactType;
    business_type: BusinessType;
    message: string;
}

export const sendContact = (params: Callback & WithData<ContactData>) => {
    sendFetch({
        url: URLS.POST.CONTACT,
        method: FetchMethod.POST,
        data: params.data,
        callback: params.callback,
    });
};