import React from 'react';
import {ThemeShape} from '@dvrd/dvr-controls';

export interface ContextShape {
    theme: ThemeShape;
}

export interface ResponseData extends IndexedObject<any> {
    status: string;
    message?: string;
}

//===========================
// TYPES
//===========================
export type IndexedObject<T> = { [index: string]: T };
export type ErrorType = string | null;
export type ChangeFunction = (value: any) => void;
export type ChangeKeyFunction = (key: string) => ChangeFunction;
export type Navigation = {
    route: string, isUrl?: boolean, scrollId?: string, label: string, subNavigation?: Navigation[],
    component: React.ReactElement, mobileLabel?: string,
};

//===========================
// ENUMS
//===========================
export enum FetchMethod {POST = 'POST'}

export enum ContactType {QUESTION = 'Vraag', QUOTATION = 'Offerte', MISC = 'Overig'}

export enum BusinessType {ZZP = 'ZZP-ondernemer / Eenmanszaak', VOF = 'VOF', PRIVATE = 'Particulier'}

export enum CSSDirection {LTR = 'ltr'}