import {polyfill} from "seamless-scroll-polyfill";
import './settings';
import {IndexedObject} from "./interfaces";
import * as Sentry from '@sentry/react';
import {getMode} from '@dvrd/dvr-controls';

declare global {
    interface Window {
        log: Function;
        mode: string;
        settings: IndexedObject<any>;
        modeSettings: { [index: string]: any };
        dce: Function;
        allowCookies: boolean;
        dataLayer: any;
        gtag?: Function;
    }
}

if (getMode() === 'prod')
    Sentry.init({dsn: "https://f0cad0030a0e4de895ed6e6e7eef953b@o215057.ingest.sentry.io/5339024"});

polyfill();