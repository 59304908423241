import {IndexedObject} from "./interfaces";

export interface SystemOptions {
    platformUrl: string;
    sentryUrl: string | null;
    appBaseUrl: string;
    gaID: string | null;
    mode: 'prod' | 'test' | 'dev';
}

interface Settings extends IndexedObject<SystemOptions> {
    prod: SystemOptions;
    dev: SystemOptions;
    accept: SystemOptions;
}

const defaultSettings: SystemOptions = {
    mode: 'prod',
    platformUrl: '',
    sentryUrl: null,
    appBaseUrl: '',
    gaID: null,
};

const appSettings: Settings = {
    prod: {
        ...defaultSettings,
        platformUrl: 'https://davevanrijn.nl/hvr-core/v1/',
        gaID: 'UA-154665398-1',
    },
    accept: {
        ...defaultSettings,
        mode: 'test',
        platformUrl: 'https://davevanrijn.nl/hvr-core/v1/',
    },
    dev: {
        ...defaultSettings,
        mode: 'dev',
        platformUrl: 'http://127.0.0.1:30377/v1/',
    }
};

const mode = window.mode || 'prod';
window.settings = appSettings[mode];
