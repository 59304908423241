import React from "react";
import Home from "./components/home/home";
import {Navigation} from "./util/interfaces";
import ServicePage from "./components/service/servicePage";
import PrivatePage from "./components/private/privatePage";
import OfferPage from "./components/offer/offerPage";
import ContactPage from "./components/contact/page/contactPage";
import {Navigate, RouteObject} from "react-router-dom";
import App from "./app";

export const NavigationItems: Navigation[] = [
    {label: 'Home', route: '/home', scrollId: 'home-content', component: <Home/>},
    {label: 'Diensten', route: '/diensten', scrollId: 'service-content', component: <ServicePage/>},
    {
        label: 'Privé administratie',
        mobileLabel: 'Privé',
        route: '/prive',
        scrollId: 'private-content',
        component: <PrivatePage/>,
    },
    {label: 'Tarieven', route: '/tarieven', scrollId: 'offer-page', component: <OfferPage/>},
    {label: 'Contact', route: '/contact', scrollId: 'contact-content', component: <ContactPage/>},
];

export const Routes: Array<RouteObject> = [
    {
        path: '*', element: <App/>, children: [
            {path: 'home', element: <Home/>},
            {path: 'diensten', element: <ServicePage/>},
            {path: 'prive', element: <PrivatePage/>},
            {path: 'tarieven', element: <OfferPage/>},
            {path: 'contact', element: <ContactPage/>},
            {path: '*', element: <Navigate to='/home'/>},
        ]
    }
];