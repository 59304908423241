import React, {useEffect, useRef, useState} from 'react';
import {Outlet} from "react-router-dom";
import Cookies from 'js-cookie';

import './util/startup';
import {PopupController} from "./components/misc/popupController";
import FooterController from "./components/footer/footerController";
import {WithHelmet} from "./components/misc/withHelmet";
import CookieConsent from "./components/cookieConsent/cookieConsent";
import {Header} from "./components/header/header";
import {AppNavigator} from '@dvrd/dvr-controls';

export default function App() {
    const [cookiesConfirmed, setCookiesConfirmed] = useState(true);
    const cookiesTimeout = useRef<number | null>(null);

    function onCloseCookieConsent() {
        setCookiesConfirmed(true);
    }

    function checkCookieConsent() {
        if (!!cookiesTimeout.current) return;
        cookiesTimeout.current = window.setTimeout(getCookieConsent, 2000);
    }

    function getCookieConsent() {
        const consent = Cookies.get('cookie_consent');
        if (consent) {
            window.allowCookies = consent === '1';
            // Renew cookie
            Cookies.set('cookie_consent', consent, {expires: 31});
        } else setCookiesConfirmed(false);
        cookiesTimeout.current = null;
    }

    function clearCookieTimeout() {
        if (cookiesTimeout.current !== null) {
            window.clearTimeout(cookiesTimeout.current);
            cookiesTimeout.current = null;
        }
    }

    useEffect(() => {
        checkCookieConsent();

        return function () {
            clearCookieTimeout();
        }
    }, []);

    return (
        <WithHelmet title='HVR Administraties' canonical='https://hvradministraties.nl/'>
            <AppNavigator/>
            <Header/>
            <div className='page'>
                <Outlet/>
            </div>
            <FooterController/>
            <PopupController/>
            <CookieConsent onClose={onCloseCookieConsent} visible={!cookiesConfirmed}/>
        </WithHelmet>
    )
}