import './style/offerPage.scss';

import React, {useEffect} from 'react';
import ParBlock from "../parBlock/parBlock";
import {WithHelmet} from "../misc/withHelmet";
import {sendGaPageview} from "../../util/analytics";
import Logo from "../logo/logo";
import {parseMoney} from '@dvrd/dvr-controls';

export default function OfferPage() {
    useEffect(() => {
        sendGaPageview('/tarieven');
    }, []);

    function renderPrices() {
        return (
            <ParBlock colored={false}>
                <div>
                    <div className='prices-container'>
                        <Logo text='Tarieven' fontSize='1.5rem'/>
                        <div className='prices'>
                            <div className='row head'>
                                <label>Werkzaamheden</label>
                                <label>Tarief per uur</label>
                            </div>
                            {renderPriceRow('Zakelijke werkzaamheden', 35)}
                            {renderPriceRow('Aangifte inkomstenbelasting', 37.5)}
                            {renderPriceRow('Gezamenlijke aangifte inkomstenbelasting', 56.25)}
                            <label className='disclaimer'>Getoonde tarieven zijn exclusief 21% BTW</label>
                        </div>
                    </div>
                </div>
            </ParBlock>
        );
    }

    function renderPriceRow(label: string, price: number) {
        return (
            <div className='row'>
                <label>{label}</label>
                <label>&euro;{parseMoney(price)}</label>
            </div>
        )
    }

    function renderOffer() {
        return (
            <ParBlock id='offer-content'>
                <div className='offer-content'>
                    <div className='text-container'>
                        <label className='par-title'>Vraag direct een offerte aan</label>
                        <p className='par-text nm'>
                            Iedereen houdt graag van duidelijkheid en geen verassingen achteraf. De tarieven van
                            HVR Administraties zijn zeer transparant en overzichtelijk.
                        </p>
                        <p className='par-text'>
                            Wilt u direct inzicht in de totaal prijs van de gewenste diensten? Gebruik dan
                            onderstaand formulier om een offerte aan te vragen. Hiermee is direct duidelijk
                            welke prijs u kunt verwachten voor de diensten. De offerte dient puur als richtlijn,
                            er kunnen geen rechten aan worden ontleend.
                        </p>
                    </div>
                </div>
            </ParBlock>
        )
    }

    return (
        <WithHelmet title='HVR Administraties | Tarieven'
                    canonical='https://hvradministraties.nl/tarieven'>
            <div className='offer-page' id='offer-page'>
                {renderPrices()}
                {renderOffer()}
            </div>
        </WithHelmet>
    )
}