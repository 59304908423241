import './style/privatePage.scss';

import React, {PureComponent} from 'react';
import {WithHelmet} from "../misc/withHelmet";
import ParBlock from "../parBlock/parBlock";
import {AwesomeIcon} from '@dvrd/dvr-controls';
import {sendGaPageview} from "../../util/analytics";

export default class PrivatePage extends PureComponent {
    componentDidMount = () => {
        sendGaPageview('/prive');
    };

    render = () => {
        return (
            <WithHelmet title='HVR Administraties | Uw privé administratie'
                        canonical='https://hvradministraties.nl/prive'>
                <div className='private-page'>
                    <ParBlock id='private-content' colored={false}>
                        <div className='icon-content-container'>
                            <AwesomeIcon name='user' className='icon'/>
                            <div className='text-container'>
                                <label className='par-title'>Privé administratie</label>
                                <p className='par-text'>Aanvragen toeslagen - Wijzigen voorlopige aanslag
                                    inkomstenbelasting - Aangifte inkomstenbelasting</p>
                                <p className='par-text'>Heeft u vragen over uw privé administratie? Wilt u compleet
                                    ontzorgd worden in uw aangiften? Bij HVR Administraties bent u aan het juiste adres
                                    voor uw persoonlijke administratie. HVR Administraties ontzorgt en adviseert zodat
                                    ik de werkzaamheden kan bieden waar u bij gebaat bent. Of dit nu de afhandeling van
                                    de gehele administratie omvat of dat ik over uw schouder meekijk om u te behoeden
                                    voor fouten.</p>
                                <p className='par-text'>
                                    U kunt gebruik maken van onderstaand contactformulier als u meer informatie wenst
                                    over de verwerking van uw privé administratie.
                                </p>
                            </div>
                        </div>
                    </ParBlock>
                    <ParBlock>
                        <div className='text-container'>
                            <label className='par-title'>Aanvragen toeslagen</label>
                            <p className='par-text'>
                                Bent u op zoek naar een expert met de juiste kennis? Heeft u geen zin in de rompslomp
                                rond uw toeslagen? Ook voor het aanvragen van uw toeslagen bent u bij HVR Administraties
                                aan het juiste adres.<br/><br/>U kunt bij ons terecht voor advies en afhandeling omtrent
                                de aanvraag van:
                            </p>
                            <div className='par-text'>
                                <div className='par-list'>
                                    <label className='item'>Zorgtoeslag</label>
                                    <label className='item'>Huurtoeslag</label>
                                    <label className='item'>Kinderopvangtoeslag</label>
                                    <label className='item'>Kindgebonden budget</label>
                                </div>
                            </div>
                        </div>
                    </ParBlock>
                </div>
            </WithHelmet>
        )
    }
}