import './style/parBlock.scss';

import React, {PropsWithChildren} from 'react';
import classNames from 'classnames';
import {CSSDirection} from "../../util/interfaces";

interface Props {
    colored?: boolean;
    direction?: CSSDirection;
    title?: string;
    id?: string;
}

export default function ParBlock(props: PropsWithChildren<Props>) {
    const {id, title, children} = props;
    const colored = props.colored ?? true;
    const direction = props.direction ?? CSSDirection.LTR;

    return (
        <div className={classNames('par-block', colored && 'colored', direction)} id={id}>
            {colored && <div className='border'/>}
            <div className='content-container'>
                {!!title && <label className='par-title'>{title}</label>}
                {children}
            </div>
            {colored && <div className='border'/>}
        </div>
    )
}