import './style/header.scss';

import React from 'react';
import classNames from 'classnames';
import {AwesomeIcon, hasHover, navigate} from '@dvrd/dvr-controls';
import {getImg, pageScroller} from "../../util/utils";
import Logo from "../logo/logo";
import {Navigation} from "../../util/interfaces";
import {sendGaEvent} from "../../util/analytics";
import {NavigationItems} from "../../routes";

interface State {
    openSubs: string[];
    scrolling: boolean;
}

export class Header extends React.Component<{}, State> {
    isScrolling: boolean = false;

    state: State = {
        openSubs: new Array<string>(),
        scrolling: window.scrollY > 0,
    };

    onNavigate = (item?: Partial<Navigation>) => () => {
        if (item) {
            const {route, scrollId} = item, move: string | boolean = scrollId || true;
            if (route) {
                sendGaEvent('navigate', 'header', route);
                navigate(route, true, move, pageScroller);
            }
        }
    };

    onClickSub = (item: Navigation) => () => {
        const {openSubs} = this.state;
        if (openSubs.includes(item.label))
            openSubs.splice(openSubs.indexOf(item.label), 1);
        else openSubs.push(item.label);
        this.setState({openSubs});
    };

    onClickTop = () => {
        sendGaEvent('click', 'to-top');
        window.scrollTo({top: 0, behavior: 'smooth'});
    };

    clickListener = () => {
        const subMenus = document.querySelectorAll('div.navigation-item-container.with-sub'), {openSubs} = this.state;
        for (const subMenu of subMenus) {
            const label = (subMenu as HTMLDivElement).dataset.label || '';
            if (!hasHover((subMenu as HTMLDivElement)) && openSubs.includes(label))
                openSubs.splice(openSubs.indexOf(label), 1);
        }
        this.setState({openSubs});
    };

    scrollListener = () => {
        if (!this.isScrolling) {
            window.requestAnimationFrame(() => {
                this.setState({scrolling: window.scrollY > 0});
                this.isScrolling = false;
            });
            this.isScrolling = true;
        }
    };

    addClickListener = () => {
        document.addEventListener('click', this.clickListener);
    };

    removeClickListener = () => {
        document.removeEventListener('click', this.clickListener);
    };

    renderNavigation = () => {
        return NavigationItems.map((item: Navigation, key: any) => {
            const {label, route, subNavigation, mobileLabel} = item;
            if (subNavigation !== undefined && subNavigation !== null && subNavigation.length >
                0) return this.renderWithSubNavigation(item, key);
            if (route !== null && route !== undefined)
                return (
                    <div key={key} className='navigation-item-container' onClick={this.onNavigate(item)}>
                        <label className={classNames('navigation-item',
                            mobileLabel === undefined && 'forced')}>{label}</label>
                        {mobileLabel !== undefined && <label className='navigation-item mobile'>{mobileLabel}</label>}
                    </div>
                );
        });
    };

    renderWithSubNavigation = (item: Navigation, key: any): React.ReactNode => {
        const {openSubs} = this.state;
        if (item.subNavigation !== null && item.subNavigation !== undefined)
            return (
                <div key={key} className='navigation-item-container with-sub' onClick={this.onClickSub(item)}
                     data-label={item.label}>
                    <label className='navigation-item'>{item.label}</label>
                    <div className={classNames('sub-menu-container', openSubs.includes(item.label) && 'open')}>
                        {item.subNavigation.map((item: Navigation, key: any) => (
                            <div key={key} className='sub-navigation-container' onClick={this.onNavigate(item)}>
                                <label className='sub-navigation-label'>{item.label}</label>
                            </div>
                        ))}
                    </div>
                </div>
            );
        return null;
    };

    componentDidMount = () => {
        this.addClickListener();
        window.addEventListener('scroll', this.scrollListener);
    };

    componentWillUnmount = () => {
        this.removeClickListener();
        window.removeEventListener('scroll', this.scrollListener);
    };

    render = () => {
        const {scrolling} = this.state;
        return (
            <>
                <div className='header-image-container'>
                    <img src={getImg('header')} alt='HVR Administraties' className='header-image'/>
                    <Logo className='image-logo'/>
                </div>
                <div className={classNames('application-header', scrolling && 'scrolling')} id='app-header'>
                    <div className='company-container'>
                        <Logo className='header-logo'
                              onClick={this.onNavigate({route: '/home', scrollId: 'home-content'})}/>
                    </div>
                    <div className='navigation-container'>
                        {this.renderNavigation()}
                    </div>
                    <div className={classNames('to-top-container', scrolling && 'visible')} onClick={this.onClickTop}>
                        <AwesomeIcon name='chevron-up' className='up-icon'/>
                    </div>
                </div>
            </>
        )
    };
}