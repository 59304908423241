import React from 'react';
import {ReactNode} from "react";
import {DVRHead, IndexedObject} from '@dvrd/dvr-controls';

interface Props extends IndexedObject<string | ReactNode | ReactNode[] | undefined> {
    title?: string;
    canonical?: string;
    children?: ReactNode | ReactNode[];
}

export const WithHelmet = (props: Props) => {
    const propExists = (prop?: string) => prop !== undefined;

    return (
        <>
            <DVRHead>
                {propExists(props.title) && <title>{props.title}</title>}
                {propExists(props.canonical) && <link rel='canonical' href={props.canonical}/>}
            </DVRHead>
            {props.children}
        </>
    )
};